import gql from 'graphql-tag'

const ProductoModel = {
    create: (client, {
        codigo, codigo_barras, codigo_sunat, descripcion, detalle, medida_id, categoria_id, inventario_id,
        tipo_igv, valor_unitario, precio_unitario, imagen, icbper, precios, compras_pcge, ventas_pcge, stock,
        margen_utilidad, utilidad, typeUtilidad, adquisicion, total_unico_general, stock_minimo
    }, recursos) => client.mutate({
        mutation: gql`mutation createProducto(
                        $codigo: String!, 
                        $codigo_barras: String, 
                        $codigo_sunat: String, 
                        $descripcion: String, 
                        $detalle: String, 
                        $medida_id: String, 
                        $categoria_id: String, 
                        $inventario_id: String, 
                        $tipo_igv: String, 
                        $valor_unitario: Float!, 
                        $precio_unitario: Float!, 
                        $imagen: String, 
                        $icbper: Boolean!,
                        $precios: JSON!,
                        $compras_pcge: String,
                        $ventas_pcge: String,
                        $stock: Boolean,
                        $margen_utilidad: Boolean,
                        $utilidad: Float, 
                        $typeUtilidad: String, 
                        $adquisicion: Float,
                        $total_unico_general: Float
                        $stock_minimo: Float
                     ) {
                        createProducto(                   
                        codigo: $codigo,
                        codigo_barras: $codigo_barras,
                        codigo_sunat: $codigo_sunat,
                        descripcion: $descripcion,
                        detalle: $detalle,
                        medida_id: $medida_id,
                        categoria_id: $categoria_id,
                        inventario_id: $inventario_id,
                        tipo_igv: $tipo_igv,
                        valor_unitario: $valor_unitario,
                        precio_unitario: $precio_unitario,
                        imagen: $imagen,
                        icbper: $icbper,
                        precios: $precios,
                        compras_pcge: $compras_pcge,
                        ventas_pcge: $ventas_pcge,
                        stock: $stock,
                        margen_utilidad: $margen_utilidad,
                        utilidad: $utilidad,
                        typeUtilidad: $typeUtilidad,
                        adquisicion: $adquisicion,
                        total_unico_general: $total_unico_general,
                        stock_minimo: $stock_minimo
                        ) {
                        ${recursos}
                      }
                    }`,
        variables: {
            codigo, codigo_barras, codigo_sunat, descripcion, detalle, medida_id, categoria_id, inventario_id, tipo_igv,
            valor_unitario: Number(valor_unitario), precio_unitario: Number(precio_unitario), imagen, icbper, precios,
            compras_pcge, ventas_pcge, margen_utilidad, utilidad: Number(utilidad), typeUtilidad, stock,
            adquisicion: Number(adquisicion), total_unico_general: Number(total_unico_general),
            stock_minimo: Number(stock_minimo)
        }
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query productobyid($id: String!){
                  productobyid(id: $id) {
                        ${recursos}
                    }
                }`,
        variables: {
            id: String(id)
        },
        fetchPolicy: 'no-cache'
    }),
    getProductosInventario: (client, { periodo, pendiente, procesado }, recursos) => client.query({
        query: gql`query productosInventarioByPeriodo($periodo: String!, $pendiente: Boolean, $procesado: Boolean){
            productosInventarioByPeriodo(periodo: $periodo, pendiente: $pendiente, procesado: $procesado) {
                ${recursos}
            }
        }`,
        variables: {
            periodo,
            pendiente,
            procesado
        },
        fetchPolicy: 'no-cache'
    }),
    updateProductoInventarioById: (client, { id, cantidad }, recursos) => client.mutate({
        mutation: gql`mutation updateProductoInventarioById($id: String!, $update: JSON!){
            updateProductoInventarioById(id: $id, update: $update){
                ${recursos}
            }
        }`,
        variables: {
            id: id,
            update: {
                cantidad: cantidad
            }
        }
    }),
    updateEstadoProductosInventario: (client, { periodo, productos, guardar }, recursos) => client.mutate({
        mutation: gql` mutation updateEstadoProductosInventario($periodo: String!, $productos: JSON!, $guardar: Boolean){
            updateEstadoProductosInventario(periodo: $periodo, productos: $productos, guardar: $guardar){
                ${recursos}
            }
        }`,
        variables: {
            periodo: periodo,
            productos: productos,
            guardar: guardar
        }
    }),
    update: (client, producto, recursos) => client.mutate({
        mutation: gql`mutation updateProducto($id: String!, $update: JSON!){
                  updateProducto(id: $id, update: $update) {
                      ${recursos}
                    }
                  }`,
        variables: {
            id: String(producto.id),
            update: {
                ...producto,
                utilidad: Number(producto.utilidad),
                adquisicion: Number(producto.adquisicion)
            }
        }
    }),
    deleteProductoInventarioById: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deleteProductoInventarioById($id: String!){
            deleteProductoInventarioById(id: $id) {
                ${recursos}
            }
        }`,
        variables: {
            id: id
        }
    }),
    deleted: (client, id, recursos) => client.mutate({
        mutation: gql`mutation deletedProducto($id: String!){
                  deletedProducto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: id
        }
    }),
    activar: (client, id, recursos) => client.mutate({
        mutation: gql`mutation activarProducto($id: String!){
                  activarProducto(id: $id) {
                    ${recursos}
                  }
                }`,
        variables: {
            id: id
        }
    }),
    getAll: (client, recursos) => client.query({
        query: gql`{
        productoslist{
              ${recursos}
          }
      }`,
        fetchPolicy: 'no-cache'
    }),
    combosAll: (client, recursos) => client.query({
        query: gql`{
            combosList{
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    createCombo: (client, {
        codigo, descripcion, medida_id, categoria_id, inventario_id, valor_unitario, precio_unitario,
        tipo_igv, productos_combos, compras_pcge, ventas_pcge
    }, recursos) => client.mutate({
        mutation: gql`mutation createCombo(
        $codigo: String, $descripcion: String, $medida_id: String, 
        $categoria_id: String, $inventario_id: String, $valor_unitario: Float,
        $precio_unitario: Float, $tipo_igv: String, $productos_combos: JSON, $compras_pcge: String, $ventas_pcge: String)
        {
            createCombo(
            codigo: $codigo, descripcion: $descripcion, medida_id: $medida_id, 
            categoria_id: $categoria_id, inventario_id: $inventario_id, 
            valor_unitario: $valor_unitario, precio_unitario: $precio_unitario, 
            tipo_igv: $tipo_igv,  productos_combos: $productos_combos, compras_pcge: $compras_pcge, ventas_pcge: $ventas_pcge){
                ${recursos}
            }
        }`,
        variables: {
            codigo, descripcion, medida_id, categoria_id,
            inventario_id, valor_unitario, precio_unitario,
            tipo_igv, productos_combos, compras_pcge, ventas_pcge
        }
    }),
    updateCombo: (client, { id, update, productosCombos }, recursos) => client.mutate({
        mutation: gql`mutation updateCombo($id: String, $update: JSON, $productosCombos: JSON){
                updateCombo(id: $id, update: $update, productosCombos: $productosCombos){
                    ${recursos}
             }
        }`, variables: { id, update, productosCombos }, fetchPolicy: 'no-cache'
    }),
    getProductsLimit: (client, limite, recursos) => client.query({
        query: gql`query productoslist($limite: JSON){
        productoslist(limite: $limite){
              ${recursos}
          }
      }`,
        variables: { limite },
        fetchPolicy: 'no-cache'
    }),
    countProductos: (client, recursos) => client.query({
        query: gql`{
        countProducto {
            ${recursos}
        }}`,
        context: { timeout: 480000 },
        fetchPolicy: 'no-cache'
    }),
    getProductoByCodigo: (client, { codigo, codigo_barras }, recursos) => client.query({
        query: gql`query productoByCodigo($codigo: String!, $codigo_barras: String){
        productoByCodigo( codigo: $codigo, codigo_barras: $codigo_barras) {
              ${recursos}
          }
      }`,
        variables: {
            codigo: codigo,
            codigo_barras: codigo_barras,
        },
        fetchPolicy: 'no-cache'
    }),
    getIgvProducto: (client, id, recuros) => client.query({
        query: gql`query igvProducto($id: String!){
                igvProducto(id: $id){
                   ${recuros}
            }
        }
        `,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
    searchParam: (client, param, recursos) => client.query({
        query: gql`query productoSearchParam($param: String!){
                productoSearchParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param: param
        },
        fetchPolicy: 'no-cache'
    }),
    getByParam: (client, param, recursos) => client.query({
        query: gql`query productosbyParam($param: String!){
                productosbyParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param: param
        },
        fetchPolicy: 'no-cache'
    }),
    getByInvParam: (client, param, recursos) => client.query({
        query: gql`query productosByInvParam($param: String!){
                productosByInvParam(param: $param) {
                      ${recursos}
                  }
              }`,
        variables: {
            param: param
        },
        fetchPolicy: 'no-cache'
    }),
    getByCategoriaId: (client, categoria_id, recursos) => client.query({
        query: gql`query productsByCategoriaId($categoria_id: String!){
                productsByCategoriaId(categoria_id: $categoria_id) {
                      ${recursos}
                  }
              }`,
        variables: { categoria_id },
        fetchPolicy: 'no-cache'
    }),
    validarProductos: (client, productos, recursos) => client.query({
        query: gql`query validarProductos($productos: JSON!){
            validarProductos(productos: $productos){
            ${recursos}
            }
        }`,
        variables: { productos },
        context: { timeout: 720000 },
        fetchPolicy: 'no-cache'
    }),
    getBycodigo: (client, codigo, recursos) => client.query({
        query: gql`query productoBycodigo($codigo: String){
            productoBycodigo(codigo:$codigo){
                ${recursos}
            }
        }`,
        variables: { codigo },
        fetchPolicy: 'no-cache'
    }),
    getByCodigos: (client, codigos, recursos) => client.query({
        query: gql`query productosByCodigos($codigos: JSON!) {
            productosByCodigos(codigos: $codigos) {
                ${recursos}
            }
        }`,
        variables: { codigos },
        fetchPolicy: 'no-cache'
    }),
    getByCodigoBarras: (client, { producto_id, codigo_barras }, recursos) => client.query({
        query: gql`query productoByCodigoBarras($producto_id: String!, $codigo_barras: String!) {
            productoByCodigoBarras(producto_id: $producto_id, codigo_barras: $codigo_barras) {
                ${recursos}
            }
        }`,
        variables: { producto_id: producto_id ? producto_id : '6fcd6ce5-cbee-49f3-b95b-a429b67ce478', codigo_barras },
        fetchPolicy: 'no-cache'
    }),
    createProductoMasivo: (client, productos, recursos) => client.query({
        query: gql`query createProductosMasivos($productos: JSON!){
            createProductosMasivos(productos: $productos) {
                status
            }
        }`,
        variables: { productos },
        context: { timeout: 1200000 },
        fetchPolicy: 'no-cache'

    }),
    getByTop: (client, recursos) => client.query({
        query: gql`{
            productosTop{
                  ${recursos}
              }
          }`,
        fetchPolicy: 'no-cache'
    }),
    getCuentaFinanciera: (client, { inventario, medida }, recursos) => client.query({
        query: gql`query cuentasDefaultFinancieras($inventario: String, $medida: String){
            cuentasDefaultFinancieras(inventario:$inventario, medida: $medida){
                ${recursos}
            }
        }`,
        variables: { inventario, medida },
        fetchPolicy: 'no-cache'
    }),
    updateMasivo: (client, { productos }, recursos) => client.mutate({
        mutation: gql`mutation updateProductsCodeFabricacion($productos: JSON!){
            updateProductsCodeFabricacion(productos: $productos) {
                ${recursos}
            }
        }`,
        variables: { productos },
        fetchPolicy: 'no-cache'
    }),
    excelproductoslist: (client, recursos) => client.query({
        query: gql`{
            excelproductoslist{
                ${recursos}
            }
        }`,
        context: { timeout: 240000 },
        fetchPolicy: 'no-cache'
    }),
    stockByProducto: (client, producto_id, fecha_emision) => client.query({
        query: gql`query controlStockProducto($producto_id: String!, $fecha_emision: String) {
            controlStockProducto(producto_id: $producto_id, fecha_emision: $fecha_emision) {
                data, controlStock
            }
        }`,
        variables: { producto_id, fecha_emision },
        fetchPolicy: 'no-cache'
    }),
    stockMinimoProductos: (client, { periodo, conNotas }, recursos) => client.query({
        query: gql`query stockMinimoProductos($periodo: String! $conNotas: Boolean){
            stockMinimoProductos(periodo: $periodo, conNotas: $conNotas){
                data, controlStock
            }
        }`,
        variables: { periodo, conNotas },
        fetchPolicy: 'no-cache'
    }),
    stockByProductoLocales: (client, producto_id, fecha_Inicio, fecha_Fin) => client.query({
        query: gql`query controlStockProductoByLocales($producto_id: String!, $fecha_Inicio: String, $fecha_Fin: String) {
            controlStockProductoByLocales(producto_id: $producto_id, fecha_Inicio: $fecha_Inicio, fecha_Fin: $fecha_Fin) {
                data, controlStock
            }
        }`,
        variables: { producto_id, fecha_Inicio, fecha_Fin },
        fetchPolicy: 'no-cache'
    }),
    // stockByProductoLocalesAll: (client, productoName, fecha_emision) => client.query({
    //     query: gql`query getStockByLocalesProductosTodos($productoName: String!, $fecha_emision: String) {
    //         getStockByLocalesProductosTodos(productoName: $productoName, fecha_emision: $fecha_emision)
    //     }`,
    //     variables: { productoName, fecha_emision },
    //     fetchPolicy: 'no-cache'
    // }),
    getByDefault: (client, recursos) => client.query({
        query: gql`{
            productosByDefault{
                  ${recursos}
              }
          }`,
        fetchPolicy: 'no-cache'
    }),
    createBarcode: (client, { codigo_barras }, recursos) => client.query({
        query: gql`query createBarcode($codigo_barras: String!) {
            createBarcode(codigo_barras: $codigo_barras) {
                ${recursos}
            }
        }`,
        variables: { codigo_barras },
        fetchPolicy: 'no-cache'
    }),

    productoColores: (client, productoName) => client.query({
        query: gql`query productoColores($productoName: String){
            productoColores(productoName: $productoName){
                id, codigo, descripcion, local_id
            }
        }`,
        variables: { productoName },
        fetchPolicy: 'no-cache'
    }),
    productosPorDescripcion: (client, productoName) => client.query({
        query: gql`query productosPorDescripcion($productoName: String){
            productosPorDescripcion(productoName: $productoName){
                id, codigo, descripcion, local_id
            }
        }`,
        variables: { productoName },
        fetchPolicy: 'no-cache'
    }),
}

export default ProductoModel
