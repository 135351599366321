import gql from 'graphql-tag'

const PosCajasModel = {
    createPosCajas: (client, {code, zona, cuenta, description}) => client.mutate({
        mutation: gql`mutation createPosCaja(
            $code        : String
            $zona        : JSON
            $description : String
            $cuenta      : JSON
        ){
            createPosCaja(
                code        : $code
                zona        : $zona
                cuenta      : $cuenta
                description : $description
            ){
                id
            }
        }`,
        variables: {
            code, zona, cuenta, description
        }
    }),
    updatePosCaja: (client, {id, code, zona, cuenta, description}) => client.mutate({
        mutation: gql`mutation updatePosCaja(
            $id          : String
            $code        : String
            $zona        : JSON
            $description : String
            $cuenta      : JSON
        ){
            updatePosCaja(
                id          : $id
                code        : $code
                zona        : $zona
                cuenta      : $cuenta
                description : $description
            ){
                id
            }
        }`,
        variables: {
            id, code, zona, cuenta, description
        }
    }),
    statusPosCaja: (client, {id, enable}) => client.mutate({
        mutation: gql`mutation statusPosCaja(
                $id:     String
                $enable: Boolean
            ){
                statusPosCaja(
                    id:     $id
                    enable: $enable
                )
                {
                    id
                }
            }
        `,
        variables: {
            id, enable
        }
    }),
    CajasByLocal: (client, recursos, local_id = null) => client.query({
        query: gql`query posCajasByLocal($local_id: String){
                        posCajasByLocal(local_id: $local_id) {
                            ${recursos}
                        }
                    }`,
        variables: {
            local_id
        },
        fetchPolicy: 'no-cache'
    }),
    getById: (client, id, recursos) => client.query({
        query: gql`query posCaja($id: String!){
                posCaja(id: $id) {
                      ${recursos}
                  }
              }`,
        variables: {
            id
        },
        fetchPolicy: 'no-cache'
    }),
}

export default PosCajasModel